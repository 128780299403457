<script setup lang="ts">
import {
  badge,
  type BadgeVariants,
} from './badge-variants';

withDefaults(defineProps<{
  color?: BadgeVariants['color'];
  noHover?: boolean;
  size?: BadgeVariants['size'];
  title: string;
  url?: string;
  variant?: BadgeVariants['variant'];
}>(), { color: 'dark',size: 'md',variant: 'solid', })

const element = computed(() => (__props.url
  ? resolveComponent('NuxtLink')
  : 'div'));
</script>

<template>
  <component
    :is="element"
    :class="badge({ color, size, variant, class: $attrs.class as string })"
    :to="url"
  >
    <span v-text="title" />
  </component>
</template>
